import {
	GET_INVENTORY,
	GET_WAREHOUSES,
	GET_INVENTORY_PRODUCTS,
	UPDATE_STOCK_STATUS,
	UPDATE_STOCK,
	TRANSFER_STOCK,
	DOWNLOAD_INVENTORY,
	GET_INVENTORY_STATUSES,
	GET_INVENTORY_CATEGORIES,
	GET_INVENTORY_CHANGES,
	GET_INVENTORY_IN_TRANSIT,
	GET_INVENTORY_CHANGES_DETAIL,
	COMPLETE_STOCK_TRANSFER,
	CANCEL_STOCK_TRANSFER,
} from '@/config/endpoint';
import Req from './AxiosRequest';
import Req2 from './AxiosRequestEnhanced';

class Inventory {
	constructor() {
		const axios = new Req();
		const axios2 = new Req2();
		this.data = axios;
		this.data2 = axios2;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getInventory(options) {
		const { method, endpoint } = GET_INVENTORY;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	getWarehouses(options = {}) {
		const { method, endpoint } = GET_WAREHOUSES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	getProducts(options = {}) {
		const { method, endpoint } = GET_INVENTORY_PRODUCTS;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getStatuses() {
		const { method, endpoint } = GET_INVENTORY_STATUSES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	getCategories() {
		const { method, endpoint } = GET_INVENTORY_CATEGORIES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	updateStockStatus(payload) {
		const { method, endpoint } = UPDATE_STOCK_STATUS;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	updateStock(payload) {
		const { method, endpoint } = UPDATE_STOCK;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	transferStock(payload) {
		const { method, endpoint } = TRANSFER_STOCK;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	download(data) {
		const { method, endpoint } = DOWNLOAD_INVENTORY;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	changes(params) {
		const { method, endpoint } = GET_INVENTORY_CHANGES;
		return this.data2[method](endpoint, params)
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}

	inTransit() {
		const { method, endpoint } = GET_INVENTORY_IN_TRANSIT;
		return this.data2[method](endpoint)
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}

	changesDetail(id) {
		const { method, endpoint } = GET_INVENTORY_CHANGES_DETAIL;
		return this.data2[method](endpoint(id))
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}

	completeStockTransfer(id, payload) {
		const { method, endpoint } = COMPLETE_STOCK_TRANSFER;
		return this.data[method](endpoint(id), payload)
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}

	cancelStockTransfer(id, payload) {
		const { method, endpoint } = CANCEL_STOCK_TRANSFER;
		return this.data[method](endpoint(id), payload)
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}
}

export default Inventory;
