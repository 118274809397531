<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					class="float-right ml-2"
					variant="primary"
					@click="downloadReport">
					<i class="fas fa-file-excel" /> {{ translate('export') }}
				</b-button>
				<b-button
					v-if="$can('inventory', 'update')"
					v-b-modal="'update_stock_modal'"
					class="float-right"
					variant="primary"
					v-text="translate('update_stock')" />
				<b-button
					v-if="$can('inventory', 'update')"
					v-b-modal="'transfer_stock_modal'"
					class="float-right mr-2"
					variant="primary"
					v-text="translate('move_stock')" />
				<b-button
					v-if="$can('orders', 'create')"
					class="float-right text-white mr-2"
					variant="info"
					@click="$router.push({ name: 'ManualOrder', params: { goBack: true } })"
					v-text="translate('create_order')" />
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						class="col-sm">
						<div class="form-group">
							<label
								for="warehouse_id"
								class="label">{{ translate('warehouse') }}</label>
							<select
								id="warehouse_id"
								v-model="filters.warehouse_id"
								name="warehouse_id"
								class="form-control">
								<option
									:value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="warehouse in warehouses"
									:key="warehouse.id"
									:value="warehouse.id">
									{{ warehouse.attributes.name }}
								</option>
							</select>
						</div>
					</div>
					<div
						class="col-sm">
						<div class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="$route.name === 'AllInventory'"
						class="col-sm">
						<div class="form-group">
							<label
								for="categories"
								class="label">{{ translate('inventory_type') }}</label>
							<select
								id="categories"
								v-model="filters.categories"
								name="categories"
								class="form-control">
								<option
									:value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="category in categories"
									:key="category"
									:value="category">
									{{ translate(`${category}_products`) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div
					class="row">
					<div class="col-sm col-xs-12">
						<div
							id="formSelector"
							class="form-group">
							<label
								class="label">{{ translate('product') }}</label>
							<multiselect
								:items-import="products"
								:loading="productsLoading"
								:selected-items-import="selectedProducts"
								:selected-items.sync="selectedProducts"
								:placeholder="translate('products')"
								:close="showFilters" />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="row">
			<div :class="['xs'].includes(windowWidth) ? 'col' : 'col-auto'">
				<div class="mb-2">
					<b-button-group
						:vertical="['xs'].includes(windowWidth)"
						:style="['xs'].includes(windowWidth) ? 'min-width: 100%' : ''">
						<b-button
							v-for="(salesDay, index) in salesDays"
							:key="index"
							:disabled="loading"
							:variant="!custom && Number(days) === salesDay ? 'success' : 'outline-success'"
							@click="changeDays(salesDay)">
							{{ translate('last_days', { days: salesDay }) }}
						</b-button>
						<b-button
							:disabled="loading"
							:variant="custom || !salesDays.includes(Number(days)) ? 'success' : 'outline-success'"
							@click="custom = true; customDays = days">
							{{ translate('custom') }}
						</b-button>
					</b-button-group>
					<div
						class="row">
						<div class="col-12">
							<switch-toggle
								id="hide_zero_values"
								v-model="hideZero"
								:disabled="loading"
								name="hide_zero_values"
								variant="success"
								class="mr-2"
								pill />
							<label for="hide_zero_values">
								{{ translate('hide_zero_values') }}
							</label>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="custom || !salesDays.includes(Number(days))"
				class="col-12 col-lg-auto col-xl-auto col-sm-auto">
				<form @submit="changeDays(customDays, $event)">
					<div class="d-flex mb-2">
						<b-input-group
							:prepend="translate('last')"
							:append="translate('days')">
							<input
								v-model="customDays"
								:disabled="loading"
								min="1"
								autofocus
								type="number"
								class="form-control text-center"
								style="max-width: 60px">
						</b-input-group>
						<div class="input-group-append ml-1">
							<b-button
								:disabled="loading"
								variant="outline-success"
								type="submit">
								{{ translate('submit') }}
							</b-button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query: { days, ...query, categories: undefined } }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		<b-table-simple
			sticky-header="600px"
			responsive
			striped
			hover
			table-class="text-nowrap">
			<thead style="position: sticky; top: 0">
				<tr>
					<th
						class="border-top-0 pointer"
						@click="sortByField('title')">
						{{ translate('warehouse') }}
						<sort field="title" />
					</th>
					<th class="border-top-0">
						{{ translate('product') }}
					</th>
					<th
						class="border-top-0 pointer"
						@click="sortByField('sku')">
						{{ translate('sku') }}
						<sort field="sku" />
					</th>
					<th
						v-if="$route.name === 'AllInventory'"
						class="border-top-0 pointer"
						@click="sortByField('category')">
						{{ translate('inventory_type') }}
						<sort field="category" />
					</th>
					<th
						class="border-top-0 text-center pointer"
						@click="sortByField('available_qty')">
						{{ translate('available') }}
						<sort field="available_qty" />
					</th>
					<th
						class="border-top-0 text-center">
						{{ translate('in_transit_qty') }}
					</th>
					<th
						class="border-top-0 text-right pointer"
						@click="sortByField('cost_per_unit')">
						{{ translate('cost_per_unit') }}
						<sort field="cost_per_unit" />
					</th>
					<th
						class="border-top-0 text-right pointer"
						@click="sortByField('total_cost')">
						{{ translate('total_cost') }}
						<sort field="total_cost" />
					</th>
					<th
						class="border-top-0 text-right pointer"
						@click="sortByField('total_sales')">
						{{ translate('total_sales') }}
						<sort field="total_sales" />
					</th>
					<th
						class="border-top-0 text-center pointer"
						@click="sortByField('sales_qty')">
						{{ translate('sales') }}
						<sort field="sales_qty" />
					</th>
					<th
						class="border-top-0 text-center pointer"
						@click="sortByField('sales_by_day')">
						{{ translate('sales_by_day') }}
						<sort field="sales_by_day" />
					</th>
					<th
						class="border-top-0 text-center pointer"
						@click="sortByField('remaining_days')">
						{{ translate('remaining_days') }}
						<sort field="remaining_days" />
					</th>
					<th
						class="border-top-0 text-center">
						{{ translate('status') }}
					</th>
					<th
						v-if="$can('inventory', 'update')"
						class="border-top-0 text-center">
						{{ translate('options') }}
					</th>
				</tr>
			</thead>
			<tbody v-if="!loading && hasData">
				<transition
					v-for="(item, index) in data"
					:key="index"
					name="slide-fade">
					<tr
						v-if="isParent(item) || showChild(item)"
						@click="handleRowClick(item)">
						<td
							:class="`align-middle font-weight-bold ${!isParent(item) ? 'text-right' : 'text-left'} ${customClasses(item)}`"
							:colspan="isParent(item) ? 1 : $route.name === 'AllInventory' ? 4 : 3">
							<em
								v-if="!isFiltering && isParent(item)"
								:class="`fas fa-chevron-${showChild(item) ? 'up' : 'down'}`" />
							{{ item.attributes.warehouse_name }}
						</td>
						<td
							v-if="isParent(item)"
							:class="`align-middle ${customClasses(item)}`">
							<img
								v-if="item.attributes.image !== null"
								:src="item.attributes.image"
								class="responsive block-inline"
								style="max-width:32px">
							<div
								v-if="item.attributes.image === null"
								style="width:32px; display:inline-block;" />
							{{ translate(item.attributes.code_name) }} {{ item.attributes.is_free ? `(${translate('free')})` : '' }}
						</td>
						<td
							v-if="isParent(item)"
							:class="`align-middle ${customClasses(item)}`">
							{{ item.attributes.sku }}
						</td>
						<td
							v-if="isParent(item) && $route.name === 'AllInventory'"
							:class="`align-middle ${customClasses(item)}`">
							{{ translate(`${item.attributes.category}_products`) }}
						</td>
						<td :class="`align-middle text-center ${customClasses(item)}`">
							{{ item.attributes.available_qty }}
						</td>
						<td :class="`align-middle text-center ${customClasses(item)}`">
							{{ item.attributes.in_transit_qty }}
						</td>
						<td
							:class="`align-middle text-right ${customClasses(item)}`">
							<template v-if="productCogs(item.attributes.sku, item.attributes.warehouse_id).length > 1">
								<span
									:id="`${item.attributes.sku}-${item.attributes.warehouse_id}-cogs`"
									class="fa fa-info-circle text-info pointer drb-pbv-popover-trigger" />
								<b-popover
									:target="`${item.attributes.sku}-${item.attributes.warehouse_id}-cogs`"
									triggers="hover"
									custom-class="drb-pbv-popover"
									boundary-padding="0"
									:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'left'">
									<div class="p-1 pb-2">
										<template>
											<div class="pbv-popover">
												<div
													class="table-responsive mb-0">
													<table class="table text-nowrap">
														<thead>
															<tr class="text-center">
																<th class="p-2 text-center">
																	{{ translate('start_date') }}
																</th>
																<th class="p-2 text-left">
																	{{ translate('end_date') }}
																</th>
																<th class="p-2 align-middle">
																	{{ translate('cost_per_unit') }}
																</th>
															</tr>
														</thead>
														<tbody>
															<tr
																v-for="(cog, cogIndex) in productCogs(item.attributes.sku, item.attributes.warehouse_id)"
																:key="cogIndex">
																<td class="p-2 text-center">
																	{{ $moment(cog.from_date).format(dateFormat) }}
																</td>
																<td class="p-2 text-center">
																	{{ $moment(cog.to_date).format(dateFormat) }}
																</td>
																<td class="p-2 align-middle text-right">
																	{{ cog.cost_per_unit }}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</template>
									</div>
								</b-popover>
							</template>
							{{ item.attributes.cost_per_unit }}
						</td>
						<td
							:class="`align-middle text-right ${customClasses(item)}`">
							{{ item.attributes.total_cost }}
						</td>
						<td
							:class="`align-middle text-right ${customClasses(item)}`">
							{{ item.attributes.total_sales }}
						</td>
						<td :class="`align-middle text-center ${customClasses(item)}`">
							{{ item.attributes.sales_qty }}
						</td>
						<td :class="`align-middle text-center ${customClasses(item)}`">
							{{ item.attributes.sales_by_day }}
						</td>
						<td :class="`align-middle text-center ${customClasses(item)}`">
							{{ item.attributes.remaining_days === null ? translate('NA') : item.attributes.remaining_days }}
						</td>
						<td :class="`align-middle text-center ${customClasses(item)}`">
							{{ translate(item.attributes.stock_status) }}
						</td>
						<td
							v-if="$can('inventory', 'update')"
							:class="`align-middle text-center ${customClasses(item)}`">
							<div v-if="isParent(item) && !isFiltering">
								<small
									v-if="!showChild(item)"
									class="text-wrap">
									{{ translate('collapsed_message') }}
								</small>
								<small
									v-else
									class="text-wrap">
									{{ translate('expanded_message') }}
								</small>
							</div>
							<template v-else>
								<b-button
									v-if="$can('inventory', 'update')"
									v-b-tooltip.hover
									v-b-modal="'update_stock_status'"
									:title="translate('update_thing', {name: translate('status')})"
									variant="primary"
									class="mr-1 bg-primary-alt"
									@click="currentItem = item">
									<i class="fa fa-edit" />
								</b-button>
							</template>
						</td>
					</tr>
				</transition>
			</tbody>
			<tfoot
				v-show="!loading && hasData"
				class="text-right"
				style="position: sticky; bottom: 0; background-color: #E9EAEA">
				<tr>
					<td :colspan="$route.name === 'AllInventory' ? 7 : 6">
						<b />
					</td>
					<td class="text-right">
						<b>{{ totals.total_cost }}</b>
					</td>
					<td class="text-right">
						<b>{{ totals.total_sales }}</b>
					</td>
					<td colspan="5">
						<b />
					</td>
				</tr>
			</tfoot>
		</b-table-simple>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<update-stock-status-modal
			:item="currentItem"
			@updated="getInventory()" />
		<update-stock-modal
			@updated="getInventory()" />
		<transfer-stock-modal
			@performed="getInventory()" />
	</div>
</template>
<script>
import {
	SALES_DAYS, DEFAULT_DAYS, MAP_CATEGORIES, DEFAULT_CATEGORY,
} from '@/settings/Inventory';
import Multiselect from '@/components/Multiselect';
import {
	Grids, Products, Inventory as InventoryMessages, Tooltip,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import WindowSizes from '@/mixins/WindowSizes';
import DataFilter from '@/components/DataFilter';
import Inventory from '@/util/Inventory';
import { YMD_FORMAT } from '@/settings/Dates';
import UpdateStockStatusModal from './UpdateStockStatusModal';
import UpdateStockModal from './UpdateStockModal';
import TransferStockModal from './TransferStockModal.vue';
import SwitchToggle from '@/components/Switch/index.vue';

export default {
	name: 'Inventory',
	messages: [Grids, Products, InventoryMessages, Tooltip],
	components: {
		SwitchToggle,
		DataFilter,
		Multiselect,
		UpdateStockStatusModal,
		UpdateStockModal,
		TransferStockModal,
	},
	mixins: [FiltersParams, WindowSizes],
	data() {
		return {
			inventory: new Inventory(),
			inventoryWarehouse: new Inventory(),
			inventoryProducts: new Inventory(),
			inventoryStatuses: new Inventory(),
			inventoryCategories: new Inventory(),
			warehouses: [],
			products: [],
			selectedProducts: [],
			salesDays: SALES_DAYS,
			days: DEFAULT_DAYS,
			dateFormat: YMD_FORMAT,
			customDays: DEFAULT_DAYS,
			custom: false,
			tabIndex: 0,
			tabs: [
				{
					name: 'MainInventory',
					translate_key: 'main_products',
				},
				{
					name: 'WearablesInventory',
					translate_key: 'wearables_products',
				},
				{
					name: 'LitesInventory',
					translate_key: 'lites_products',
				},
				{
					name: 'BooksInventory',
					translate_key: 'books_products',
				},
				{
					name: 'VPackInventory',
					translate_key: 'vpack_products',
				},
				{
					name: 'SwagInventory',
					translate_key: 'swag_products',
				},
				{
					name: 'AllInventory',
					translate_key: 'all_inventory',
				},
			],
			currentExpandIds: [],
			currentItem: {},
			hideZero: false,
		};
	},
	computed: {
		loading() {
			return this.inventory.data.loading;
		},
		productsLoading() {
			return this.inventoryProducts.data.loading;
		},
		isFiltering() {
			return this.warehouses.length === 1 || Object.keys(this.$route.query).some((item) => ['warehouse_id', 'products', 'status'].includes(item));
		},
		data() {
			try {
				const { data } = this.inventory.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		query() {
			return this.$route.query;
		},
		statuses() {
			try {
				const { data } = this.inventoryStatuses.data.response.data;
				return data.map((status) => status.attributes.code_name);
			} catch (error) {
				return [];
			}
		},
		categories() {
			try {
				const { data } = this.inventoryCategories.data.response.data;
				return data.map((category) => category.attributes.code_name);
			} catch (error) {
				return [];
			}
		},
		totals() {
			try {
				const { meta } = this.inventory.data.response.data;
				return meta;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		language() {
			this.products = this.products.map((item) => ({
				...item,
				text: this.translate(item.translate_key),
			}));
			if (typeof this.selectedProducts === 'object') {
				this.selectedProducts = this.selectedProducts.map((item) => ({
					...item,
					text: this.translate(item.translate_key),
				}));
			}
		},
		hideZero() {
			this.$route.query.on_hand = this.getOnHandFilter();
			this.getInventory();
		},
	},
	beforeRouteEnter: (to, from, next) => {
		if (to.query.products && from.name !== null) {
			delete to.query.products;
			next({ ...to });
		} else {
			next();
		}
	},
	created() {
		this.showFilters = false;
		this.noFilterable.push('days');
		this.noFilterable.push('on_hand');
		this.getFiltersfromUrl();
	},
	mounted() {
		this.inventoryStatuses.getStatuses();
		this.inventoryCategories.getCategories();
		this.getMultiselectProducts();
		this.inventoryWarehouse.getWarehouses().then((response) => {
			this.warehouses = response;
		});
		this.days = this.query.days ?? DEFAULT_DAYS;
		this.on_hand = this.query.on_hand ?? undefined;
		this.hideZero = typeof this.on_hand !== 'undefined';
		this.customDays = this.days;
		this.getInventory();
	},
	methods: {
		getOnHandFilter() {
			return this.hideZero ? 'neq_0' : undefined;
		},
		getInventory(options) {
			const opts = options || this.query;
			if (this.hideZero) {
				opts.on_hand = this.getOnHandFilter();
			} else {
				delete opts.on_hand;
			}
			this.inventory.getInventory({ ...opts, days: this.days, categories: this.getCategory() });
		},
		getMultiselectProducts() {
			this.inventoryProducts.getProducts({ categories: this.getCategory() }).then((response) => {
				this.selectedProducts = this.filters.products;
				this.products = response.map((item) => ({
					value: item.attributes.sku,
					translate_key: item.attributes.code_name,
					text: item.attributes.name,
				}));
			});
		},
		getDataFiltered() {
			const { query } = this.$route;

			this.filters.products = this.selectedProducts.map((product) => product.value);

			if (!this.filters.products.length) {
				delete this.filters.products;
				delete query.products;
			}

			const options = {
				...query,
				...this.filters,
				days: this.days.toString(),
				on_hand: this.getOnHandFilter(),
			};

			Object.keys(options).forEach((item) => {
				if (typeof options[item] === 'undefined') {
					delete options[item];
				}
			});

			this.getInventory(options);

			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
		},
		changeDays(days, event) {
			if (event) {
				event.preventDefault();
			}
			this.$route.query.days = days;
			this.days = days;
			this.custom = false;
			this.getInventory();
		},
		handleRowClick(item) {
			if (!item.attributes.is_parent) return;
			if (this.currentExpandIds.includes(Number(item.id))) {
				this.currentExpandIds = this.currentExpandIds.filter((id) => id !== Number(item.id));
			} else {
				this.currentExpandIds.push(Number(item.id));
			}
		},
		isParent(item) {
			return item.attributes.is_parent || this.isFiltering;
		},
		showChild(item) {
			return this.currentExpandIds.includes(Number(item.id));
		},
		productCogs(sku, warehouseId) {
			const cogs = this.totals.cogs_in_range[sku] || [];
			return cogs.filter((cog) => cog.warehouse_id === warehouseId);
		},
		customClasses(item) {
			if (this.isFiltering) {
				return '';
			}
			if (this.isParent(item)) {
				return 'pointer';
			}
			return 'text-medium';
		},
		getCategory() {
			return this.filters.categories ? this.filters.categories : (MAP_CATEGORIES[this.$route.name] ?? DEFAULT_CATEGORY);
		},
		downloadReport() {
			const inventory = new Inventory();
			const loading = new this.$Alert();
			loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			inventory.download({ ...this.query, days: this.days }).then(() => {
				const { response } = inventory.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('inventory')} - ${this.$moment().format('YYYY-MM-DD')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				loading.close();
			}).catch(() => {
				loading.close();
			});
		},
	},
};
</script>
<style scoped>
.slide-fade-enter-active {
	transition: all .5s ease;
}
.slide-fade-leave-active {
	transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
	transform: translateX(10px);
	opacity: 0;
}
.input-group {
	max-width: 170px;
}
</style>
