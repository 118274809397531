export const SALES_DAYS = [30, 20, 10];
export const DEFAULT_DAYS = 30;
export const DEFAULT_CATEGORY = 'main';
export const MAP_CATEGORIES = {
	MainInventory: 'main',
	LitesInventory: 'lites',
	BooksInventory: 'books',
	VPackInventory: 'vpack',
	SwagInventory: 'swag',
	WearablesInventory: 'wearables',
	AllInventory: 'main,vpack,swag,wearables,lites,books',
};
export const UPDATE_STOCK_OPERATIONS = ['increment', 'decrement'];

export default {};
